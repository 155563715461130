import React from 'react';
import { PathService } from './../services';
import { ContactUsViewContext, TemplateProps } from './../types';
import { ContactUsView } from './../views';

const OurStoryPost: React.FunctionComponent<TemplateProps<
  ContactUsViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <ContactUsView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default OurStoryPost;
